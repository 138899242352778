function parseJwt(token) {
  try {
    const base64Payload = token.split('.')[1]
    const payload = window.atob(base64Payload)
    return JSON.parse(payload.toString())
  } catch (err) {
    return {
      exp: 0,
    }
  }
}

function parseJwtAndGetExpTime(token) {
  if (!token || token === 'undefined') return 0
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const decoded = JSON.parse(atob(base64))
  return decoded ? decoded?.exp * 1000 : 0
}

module.exports = {
  authHeaders(isEnrichmentHub = false) {
    const key = localStorage.getItem('key')
    const tokenStorage = key && !isEnrichmentHub ? `token_${key}` : 'token'
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(tokenStorage)}`,
      },
    }
  },

  debounce(fn, delay) {
    let timeoutID = null

    function debounced(...args) {
      clearTimeout(timeoutID)
      const thisArgs = args
      const that = this
      timeoutID = setTimeout(() => {
        fn.apply(that, thisArgs)
      }, delay)
    }

    return debounced
  },

  authHeadersWithVerificationToken() {
    const jwt = localStorage.getItem('user-verification-token')
    const key = localStorage.getItem('key')
    const csJWT = localStorage.getItem(`cs_token_${key}`)
    const tokenStorage = key ? `token_${key}` : 'token'
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(tokenStorage)}`,
        Token: jwt,
        'Cs-Token': csJWT,
      },
    }
  },

  isTokenValid() {
    const key = localStorage.getItem('key')
    const tokenStorage = key ? `token_${key}` : 'token'
    if (!localStorage.getItem(tokenStorage)) {
      return false
    }

    const jwt = parseJwt(localStorage.getItem(tokenStorage))
    return jwt.exp === undefined || jwt.exp > Date.now() / 1000
  },

  isCorrectUser(userID) {
    const key = localStorage.getItem('key')
    const tokenStorage = key ? `token_${key}` : 'token'
    if (!localStorage.getItem(tokenStorage)) {
      return false
    }

    const jwt = parseJwt(localStorage.getItem(tokenStorage))
    return jwt.userId === userID
  },

  disconnect() {
    const key = localStorage.getItem('key')
    localStorage.removeItem(`cs_token_${key}`)
    const tokenStorage = key ? `token_${key}` : 'token'
    if (!localStorage.getItem(tokenStorage)) return
    localStorage.removeItem(tokenStorage)
    // window.location.href = window.location.href
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },

  addToLocalStorageWithExpiration(key, value, ttl) {
    const expirationMS = ttl * 60 * 1000
    const expirationTime = new Date().getTime() + expirationMS
    const data = {
      value,
      expires: expirationTime,
    }
    localStorage.setItem(key, JSON.stringify(data))
  },

  isGodMode() {
    const key = localStorage.getItem('key')
    // TODO: Create wrapper for the `localStorage.getItem` method that parses the return value
    const csToken = localStorage.getItem(`cs_token_${key}`)
    const valid = parseJwtAndGetExpTime(csToken)
    if (!valid) {
      localStorage.removeItem(`cs_token_${key}`)
      return false
    }
    return true
  },

  hasValidVerificationToken() {
    const jwt = localStorage.getItem('user-verification-token')
    const valid = jwt && parseJwtAndGetExpTime(jwt)
    if (!valid) {
      localStorage.removeItem('user-verification-token')
      return false
    }
    return true
  },
}
